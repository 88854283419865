import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { useLocation } from '@reach/router'
import styled from "styled-components"
import AGBTemplateFR from "../../components/AGB/agb_fr"

const StyledWrapper = styled.div`
  margin: 2rem 5vw;
`

export default function Page() {
  const location = useLocation();
  let country ="CH"
  let lang="fr-CH"
  
  const url = typeof window !=="undefined" ? window.location.href :"";
  return (
    <Layout location={country} path={location.pathname} language={lang}>
      <Seo
        title="Nutzungsbedingungen DoryGo "
        description="Hier finden Sie die Nutzungsbedingunge DoryGo App"
      />
      <StyledWrapper>
          <AGBTemplateFR/>
      </StyledWrapper>
    </Layout>
  )
}