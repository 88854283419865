import React from "react"

const AGBTemplateFR = () => {
  return (
    <div>
<h2 >Conditions d&apos;utilisation de l&apos;application DoryGo</h2>
<h3 >Version du 02.05.2021</h3>

<div>
    <ol start="1">
        <li><strong>Validit&eacute; de ces conditions d&apos;utilisation</strong></li>
    </ol>
</div>

<p>La soci&eacute;t&eacute; DoryHealth AG, Hagenholzstrasse 81A, 8050 Zurich, Suisse, inscrite au registre du commerce du canton de Zurich sous le num&eacute;ro CHE-220.969.862 (ci-apr&egrave;s &quot;DoryHealth AG&quot;) exploite l&apos;application DoryGo. Ces conditions d&apos;utilisation r&egrave;glent la relation juridique entre DoryHealth AG et les utilisateurs de l&apos;App DoryGo. En t&eacute;l&eacute;chargeant et en s&apos;enregistrant sur l&apos;app DoryGo, les pr&eacute;sentes conditions d&apos;utilisation et la d&eacute;claration de protection des donn&eacute;es de DoryHealth AG sont consid&eacute;r&eacute;es comme accept&eacute;es. Si l&apos;utilisateur ne souhaite pas accepter ces conditions d&apos;utilisation ou la d&eacute;claration de protection des donn&eacute;es de DoryHealth AG, il doit s&apos;abstenir d&apos;utiliser les syst&egrave;mes de DoryHealth.</p>
<p>DoryHealth AG se r&eacute;serve le droit de modifier &agrave; tout moment ces conditions d&apos;utilisation, la d&eacute;claration de protection des donn&eacute;es et l&apos;application DoryGo. Les utilisateurs d&eacute;j&agrave; enregistr&eacute;s sont inform&eacute;s au pr&eacute;alable des modifications et doivent, dans le d&eacute;lai indiqu&eacute; dans la notification de modification, r&eacute;silier leur abonnement pour l&apos;utilisation de l&apos;application DoryGo pour la prochaine date de r&eacute;siliation possible, s&apos;ils ne sont pas d&apos;accord avec les modifications, sinon les modifications deviennent obligatoires dans le d&eacute;lai indiqu&eacute; et sont consid&eacute;r&eacute;es comme accept&eacute;es par l&apos;utilisateur.&nbsp;</p>
<p>Pour les prestations de la pharmacie, les rapports juridiques entre l&apos;utilisateur et la pharmacie sont soumis, le cas &eacute;ch&eacute;ant, aux conditions g&eacute;n&eacute;rales de la pharmacie.</p>

<div>
    <ol start="2">
        <li><strong>Description de l&apos;application DoryGo et des parties contractantes</strong></li>
    </ol>
</div>

<p>L&apos;application DoryGo est une application (a) par le biais de laquelle l&apos;utilisateur est mis en relation avec des pharmacies enregistr&eacute;es sur l&apos;application DoryGo pour l&apos;utilisation de l&apos;offre d&eacute;crite dans les pr&eacute;sentes conditions d&apos;utilisation, l&apos;utilisateur pouvant choisir librement parmi les pharmacies propos&eacute;es, (b) par laquelle les utilisateurs peuvent recevoir r&eacute;guli&egrave;rement, dans le cadre d&apos;un abonnement, des m&eacute;dicaments pr&eacute;-tri&eacute;s par la pharmacie s&eacute;lectionn&eacute;e dans des bo&icirc;tes &agrave; m&eacute;dicaments &agrave; l&apos;adresse souhait&eacute;e, (c) par laquelle les utilisateurs re&ccedil;oivent un plan de prise de m&eacute;dicaments g&eacute;n&eacute;r&eacute; par la pharmacie ainsi que des messages de rappel de prise de m&eacute;dicaments, et (d) par laquelle ils peuvent communiquer avec la pharmacie s&eacute;lectionn&eacute;e (ci-apr&egrave;s &quot;App DoryGo&quot;).</p>
<p>Les commandes de m&eacute;dicaments de l&apos;utilisateur s&apos;effectuent par la transmission de l&apos;ordonnance de m&eacute;dicaments &agrave; la pharmacie choisie, ce qui a valeur d'offre d&apos;achat de m&eacute;dicaments &agrave; la pharmacie. La pharmacie choisie contr&ocirc;le l&apos;exactitude et la justification de la commande transmise sur la base des ordonnances de m&eacute;dicaments qui lui ont &eacute;t&eacute; envoy&eacute;es, le cas &eacute;ch&eacute;ant. Sous r&eacute;serve d&apos;un r&eacute;sultat positif de ce contr&ocirc;le et du paiement par l&apos;Utilisateur, la pharmacie choisie acceptera la commande et enverra r&eacute;guli&egrave;rement &agrave; l&apos;Utilisateur les m&eacute;dicaments command&eacute;s, pr&eacute;-tri&eacute;s dans des bo&icirc;tes &agrave; m&eacute;dicaments.</p>
<p>DoryHealth AG n&apos;est pas partie contractante pour la livraison et le tri des m&eacute;dicaments, mais agit uniquement en tant qu&apos;interm&eacute;diaire entre les pharmacies et les prestataires de services informatiques et de communication. La v&eacute;rification des m&eacute;dicaments &agrave; prendre et des plans de m&eacute;dicaments, les questions et les &eacute;ventuelles adaptations concernant les livraisons de m&eacute;dicaments doivent &ecirc;tre effectu&eacute;es directement entre l&apos;utilisateur et la pharmacie choisie.</p>
<p>L&apos;application DoryGo ne fournit pas de conseils m&eacute;dicaux, de recommandations ou d&apos;informations diagnostiques ou th&eacute;rapeutiques de quelque nature que ce soit. L&apos;appli DoryGo ne peut &ecirc;tre utilis&eacute;e que pour l&apos;administration et la documentation des m&eacute;dicaments prescrits par un m&eacute;decin.</p>
<p>Dans l&apos;appli DoryGo, des informations et des services de pharmacies et &eacute;ventuellement d&apos;autres tiers sont mis &agrave; la disposition de l&apos;utilisateur. DoryHealth AG ne contr&ocirc;le pas l&apos;exactitude, l&apos;exhaustivit&eacute; et l&apos;actualit&eacute; des informations saisies par l&apos;utilisateur ou mises &agrave; disposition par des tiers. Les informations ne remplacent pas un examen m&eacute;dical, une &eacute;valuation et un conseil par un m&eacute;decin ou un pharmacien. Les d&eacute;cisions m&eacute;dicales doivent &ecirc;tre prises exclusivement apr&egrave;s consultation d&apos;un m&eacute;decin ou d&apos;un pharmacien.</p>
<p>Si la livraison de m&eacute;dicaments par une pharmacie est souhait&eacute;e, la pharmacie peut prendre contact par t&eacute;l&eacute;phone lors de la premi&egrave;re commande d&apos;un m&eacute;dicament afin de clarifier les questions de sant&eacute; prescrites par la loi. Ensuite, les m&eacute;dicaments sont livr&eacute;s par la pharmacie, tri&eacute;s dans des bo&icirc;tes &agrave; m&eacute;dicaments. L&apos;envoi se fait &agrave; intervalles r&eacute;guliers tant qu&apos;il y a une ordonnance valable, que l&apos;abonnement est toujours valable et qu&apos;il n&apos;y a pas d&apos;opposition.</p>
<p>Le plan de m&eacute;dication est &eacute;tabli sur la base des donn&eacute;es saisies par l&apos;utilisateur et des ordonnances soumises. DoryHealth AG ne v&eacute;rifie ni l&apos;exactitude, ni l&apos;exhaustivit&eacute;, ni l&apos;actualit&eacute; du plan de m&eacute;dication ou des indications de l&apos;utilisateur dans l&apos;application.</p>
<p>&nbsp;</p>
<p>L&apos;utilisateur peut se faire rappeler de prendre ses m&eacute;dicaments en configurant les heures de prise dans les param&egrave;tres.</p>
<p>DoryHealth AG ne v&eacute;rifie pas non plus ces r&eacute;glages. Veuillez ne pas vous fier uniquement &agrave; l&apos;application DoryGo pour le rappel de la prise de m&eacute;dicaments.</p>
<p>L&apos;utilisateur a la possibilit&eacute; de se faire rappeler le renouvellement de ses ordonnances. Notez que ce rappel se fait sur la base de la saisie de la pharmacie. Veuillez ne pas vous fier uniquement &agrave; l&apos;application DoryGo pour le renouvellement des commandes.</p>

<div>
    <ol start="3">
        <li><strong>Conditions d&apos;utilisation</strong></li>
    </ol>
</div>

<p>Pour qu&apos;un utilisateur puisse utiliser l&apos;application DoryGo, il faut que l&apos;utilisateur :</p>
<p>(a) soit &acirc;g&eacute; d&apos;au moins 18 ans ou qu&apos;il ait obtenu l&apos;accord de son repr&eacute;sentant l&eacute;gal ;</p>
<p>(b) soit familiaris&eacute; avec le num&eacute;rique et jouit de sa pleine capacit&eacute; de discernement ;</p>
<p>(c) dispose d&apos;une connexion Internet op&eacute;rationnelle ;</p>
<p>(d) remplisse compl&egrave;tement et correctement toutes les donn&eacute;es demand&eacute;es et les mette &agrave; jour en cas de changement ;</p>
<p>(e) d&eacute;pose une carte de cr&eacute;dit valable pour le d&eacute;bit des frais d&apos;abonnement ;</p>
<p>(f) donne son consentement au traitement de ses donn&eacute;es personnelles par DoryHealth AG et</p>
<p>(g) accepte les pr&eacute;sentes conditions d&apos;utilisation et les &eacute;ventuelles autres conditions de commande de la pharmacie choisie concernant la commande de m&eacute;dicaments. &nbsp; &nbsp; &nbsp;</p>
<p>&nbsp;</p>
<div>
    <ol start="4">
        <li><strong>Obligations de l&apos;utilisateur</strong></li>
    </ol>
</div>

<p>L&apos;utilisateur est tenu ,</p>
<p>(a) de ne fournir que des informations conformes &agrave; la v&eacute;rit&eacute; ;</p>
<p>(b) &agrave; ne commander des m&eacute;dicaments qu&apos;apr&egrave;s consultation d&apos;un m&eacute;decin et, dans la mesure o&ugrave; ils sont soumis &agrave; prescription, uniquement sur la base d&apos;une ordonnance m&eacute;dicale ;</p>
<p>(c) de v&eacute;rifier r&eacute;guli&egrave;rement les livraisons de m&eacute;dicaments, leur tri, le plan de m&eacute;dication et les messages de rappel de prise de m&eacute;dicaments ;</p>
<p>(d) de ne commander que les m&eacute;dicaments n&eacute;cessaires &agrave; son propre usage ou &agrave; des personnes pour lesquelles il dispose d&apos;une autorisation de pr&eacute;voyance ou de repr&eacute;sentation parentale ;</p>
<p>(e) d&apos;utiliser la version la plus r&eacute;cente de l&apos;application DoryGo.</p>
<p>La commande de m&eacute;dicaments est une offre ferme &agrave; la pharmacie choisie. En cas d&apos;acceptation de la commande de m&eacute;dicaments par la pharmacie s&eacute;lectionn&eacute;e, le contrat de vente concernant les m&eacute;dicaments est consid&eacute;r&eacute; comme conclu et le paiement est d&ucirc;.</p>

<div>
    <ol start="5">
        <li><strong>Obligations et droits de DoryHealth AG</strong></li>
    </ol>
</div>

<p>DoryHealth AG met &agrave; disposition l&apos;application DoryGo avec les fonctions d&eacute;crites au chiffre 2 des pr&eacute;sentes conditions d&apos;utilisation. DoryHealth AG peut, &agrave; sa discr&eacute;tion, actualiser l&apos;application DoryGo &agrave; tout moment et mettre &agrave; disposition des fonctions suppl&eacute;mentaires ou adapt&eacute;es. L&apos;utilisateur n&apos;est autoris&eacute; &agrave; utiliser que la version la plus r&eacute;cente de l&apos;app DoryGo.</p>
<p>DoryHealth AG a le droit de ne pas fournir les prestations ou de les interrompre si :</p>
<p>(a) l&apos;utilisateur r&eacute;voque son consentement au traitement de ses donn&eacute;es personnelles conform&eacute;ment &agrave; la d&eacute;claration de protection des donn&eacute;es de DoryHealth AG ;</p>
<p>(b) selon l&apos;appr&eacute;ciation de DoryHealth AG, il existe des indices que l&apos;utilisateur viole ces conditions d&apos;utilisation, entre autres, mais pas de mani&egrave;re exhaustive, parce que les donn&eacute;es demand&eacute;es sont fausses ou incompl&egrave;tes, ou qu&apos;une utilisation abusive a lieu ;</p>
<p>(c) les frais d&apos;abonnement ne sont pas pay&eacute;s ou la pharmacie signale des impay&eacute;s de la part de l&apos;utilisateur, ou que</p>
<p>(d) la pharmacie s&eacute;lectionn&eacute;e n&apos;est plus enregistr&eacute;e et l&apos;utilisateur n&apos;a pas encore s&eacute;lectionn&eacute; une nouvelle pharmacie.</p>

<div>
    <ol start="6">
        <li><strong>Propri&eacute;t&eacute; intellectuelle</strong></li>
    </ol>
</div>

<p>L&apos;application DoryGo, ses logiciels, textes, images, graphiques et marques sont la propri&eacute;t&eacute; intellectuelle de DoryHealth AG ou de ses partenaires contractuels, prot&eacute;g&eacute;s par le droit d&apos;auteur, le droit des marques et d&apos;autres lois. DoryHealth AG accorde &agrave; l&apos;utilisateur un droit d&apos;utilisation non exclusif, non transf&eacute;rable et non sous-licenciable de l&apos;app DoryGo ainsi que des contenus et donn&eacute;es qui y sont publi&eacute;s, aux fins d&eacute;crites au chiffre 2 des pr&eacute;sentes conditions d&apos;utilisation et dans le cadre de ces conditions d&apos;utilisation. Toute autre utilisation est interdite.</p>

<div>
    <ol start="7">
        <li><strong>Co&ucirc;ts et dur&eacute;e de l&apos;abonnement</strong></li>
    </ol>
</div>

<p>Le t&eacute;l&eacute;chargement et l&apos;inscription &agrave; l&apos;application DoryGo sont gratuits. Pour utiliser les fonctions de l&apos;appli DoryGo d&eacute;crites au point 2 des pr&eacute;sentes conditions d&apos;utilisation, il est toutefois n&eacute;cessaire de souscrire un abonnement payant.</p>
<p>Les frais d&apos;abonnement ne d&eacute;dommagent toutefois DoryHealth AG que pour l&apos;utilisation de l&apos;appli DoryGo et des bo&icirc;tes de m&eacute;dicaments (sans contenu). Les co&ucirc;ts des m&eacute;dicaments achet&eacute;s ou des autres services de la pharmacie sont factur&eacute;s directement &agrave; l&apos;utilisateur par la pharmacie ou d&eacute;compt&eacute;s avec l&apos;assurance maladie.</p>
<p>Les frais d&apos;abonnement sont payables &agrave; l&apos;avance le premier jour de la p&eacute;riode d&apos;abonnement.</p>
<p>&nbsp;</p>
<p>L&apos;utilisateur et DoryHealth AG sont autoris&eacute;s &agrave; r&eacute;silier l&apos;abonnement choisi &agrave; tout moment, avec un pr&eacute;avis d&apos;au moins 2 semaines avant l&apos;expiration de la dur&eacute;e de l&apos;abonnement, en cliquant sur le bouton correspondant de l&apos;application DoryGo. Sans r&eacute;siliation dans les d&eacute;lais, l&apos;abonnement choisi est automatiquement et ind&eacute;finiment prolong&eacute; pour une autre dur&eacute;e d&apos;abonnement identique, moyennant des frais.</p>
<p>DoryHealth AG a le droit d&apos;augmenter &agrave; tout moment les frais d&apos;abonnement, entre autres, mais pas seulement, en raison de fonctions payantes suppl&eacute;mentaires ou de nouvelles versions de l&apos;application DoryGo. DoryHealth AG communique &agrave; l&apos;utilisateur les augmentations des co&ucirc;ts d&apos;abonnement au moins 4 semaines avant le d&eacute;but d&apos;une nouvelle p&eacute;riode d&apos;abonnement, de sorte que l&apos;utilisateur puisse r&eacute;silier son abonnement &agrave; temps.</p>
<p>Si l&apos;abonnement prend fin suite &agrave; une r&eacute;siliation ou si l&apos;utilisateur clique sur la fonction &quot;Supprimer les donn&eacute;es&quot;, les donn&eacute;es personnelles et l&apos;abonnement sont irr&eacute;vocablement supprim&eacute;s ou anonymis&eacute;s dans les 30 jours. L&apos;utilisation de l&apos;application DoryGo n&apos;est ensuite plus possible. Les donn&eacute;es personnelles et l&apos;abonnement ne peuvent pas non plus &ecirc;tre restaur&eacute;s.</p>

<div>
    <ol start="8">
        <li><strong>Protection des donn&eacute;es</strong></li>
    </ol>
</div>

<p>En souscrivant un abonnement, l&apos;utilisateur charge DoryHealth AG d&apos;&eacute;tablir le contact avec la pharmacie choisie et de mettre les donn&eacute;es personnelles n&eacute;cessaires de l&apos;utilisateur &agrave; la disposition de la pharmacie.</p>
<p>Le traitement des donn&eacute;es personnelles de l&apos;utilisateur est r&eacute;gi par la d&eacute;claration de protection des donn&eacute;es de DoryHealth AG (www.dorygo.com/ch-fr/datenschutzvereinbarung), qui fait partie int&eacute;grante des pr&eacute;sentes conditions d&apos;utilisation.</p>

<div>
    <ol start="9">
        <li><strong>Exclusion de garantie et de responsabilit&eacute;</strong></li>
    </ol>
</div>

<p>L&apos;application DoryGo est un outil informatique et de communication nouvellement d&eacute;velopp&eacute; destin&eacute; &agrave; aider les utilisateurs avertis et capables de discernement dans le domaine num&eacute;rique &agrave; recevoir et &agrave; prendre des m&eacute;dicaments &quot;tels quels&quot;, sans aucune garantie. DoryHealth AG est une aide et son utilisation se fait aux risques et p&eacute;rils de l&apos;utilisateur.</p>
<p>Les bases pour la livraison et le tri des m&eacute;dicaments, le plan des m&eacute;dicaments ainsi que les messages de rappel de prise de m&eacute;dicaments proviennent tous de l&apos;utilisateur lui-m&ecirc;me (informations sur la sant&eacute;, intol&eacute;rances), de son m&eacute;decin (ordonnances) ou de la pharmacie (livraison de m&eacute;dicaments, conseils) et non de DoryHealth AG. DoryHealth ne v&eacute;rifie pas les informations fournies par l&apos;utilisateur, ni les donn&eacute;es mises &agrave; disposition par les m&eacute;decins, les pharmacies ou d&apos;autres tiers, ni l&apos;autorisation de commander des m&eacute;dicaments soumis &agrave; ordonnance, ni l&apos;indication, les heures de prise, le prix, la disponibilit&eacute; en temps voulu, l&apos;efficacit&eacute; et les risques des m&eacute;dicaments, raison pour laquelle toute garantie et responsabilit&eacute; de DoryHealth AG est exclue par la pr&eacute;sente dans la mesure autoris&eacute;e par la loi. Cette exclusion de garantie et de responsabilit&eacute; s&apos;applique &eacute;galement aux employ&eacute;s, repr&eacute;sentants et auxiliaires d&apos;ex&eacute;cution de DoryHealth.</p>
<p>Le contrat de vente des m&eacute;dicaments command&eacute;s se fait directement entre la pharmacie et l&apos;utilisateur, leur contr&ocirc;le, leur indication et leur tri sont &eacute;galement effectu&eacute;s par la pharmacie. DoryHealth AG n&apos;est qu&apos;un interm&eacute;diaire et un prestataire de services informatiques et de communication. Si l&apos;utilisateur n&apos;est pas satisfait de la vente de m&eacute;dicaments, si la livraison n&apos;est pas effectu&eacute;e dans les d&eacute;lais ou de mani&egrave;re incorrecte ou si l&apos;utilisateur ne paie pas les m&eacute;dicaments command&eacute;s, les &eacute;ventuelles r&eacute;clamations doivent &ecirc;tre adress&eacute;es directement &agrave; la pharmacie ou &agrave; l&apos;utilisateur. DoryHealth AG n&apos;est pas responsable des r&eacute;clamations entre ces parties contractantes. La pharmacie est seule responsable de la vente des m&eacute;dicaments, de leur v&eacute;rification et de l&apos;encaissement du paiement pour les m&eacute;dicaments command&eacute;s.</p>
<p>Bien que DoryHealth AG s&apos;efforce d&apos;assurer un fonctionnement ininterrompu de l&apos;application DoryGo, DoryHealth AG n&apos;est pas responsable d&apos;une indisponibilit&eacute; temporaire de l&apos;application DoryGo, de retards de transmission ou de dysfonctionnements. De plus, DoryHealth AG ne peut pas garantir que l&apos;application DoryGo est compatible avec le logiciel ou le mat&eacute;riel de l&apos;appareil mobile utilis&eacute; par l&apos;utilisateur et qu&apos;elle fonctionne. L&apos;utilisation de l&apos;application DoryGo n&apos;est pas adapt&eacute;e aux utilisateurs qui ne sont pas familiaris&eacute;s avec le num&eacute;rique.</p>
<p>Malgr&eacute; l&apos;utilisation de mesures de s&eacute;curit&eacute; appropri&eacute;es pour prot&eacute;ger les donn&eacute;es personnelles saisies par l&apos;utilisateur, comme par exemple le cryptage, la transmission de donn&eacute;es via Internet n&apos;est malheureusement jamais totalement s&ucirc;re. C&apos;est pourquoi DoryHealth AG ne peut pas garantir la s&eacute;curit&eacute; des donn&eacute;es. Toute transmission de donn&eacute;es se fait aux risques et p&eacute;rils de l&apos;utilisateur.</p>

<div>
    <ol start="10">
        <li><strong>Clause de sauvegarde</strong></li>
    </ol>
</div>

<p>Si certaines dispositions des pr&eacute;sentes conditions d&apos;utilisation sont ou deviennent caduques, les autres dispositions des pr&eacute;sentes conditions d&apos;utilisation restent valables sans restriction. La disposition invalide est remplac&eacute;e par une disposition juridiquement valable qui se rapproche le plus possible du sens et de l&apos;objectif &eacute;conomiques de la disposition invalide. La disposition ci-dessus s&apos;applique par analogie en cas de lacune r&eacute;glementaire.</p>

<div>
    <ol start="11">
        <li><strong>Droit applicable et juridiction comp&eacute;tente</strong></li>
    </ol>
</div>

<p>Les pr&eacute;sentes conditions d&apos;utilisation sont soumises au droit mat&eacute;riel suisse, &agrave; l&apos;exclusion des r&egrave;gles de conflit de lois du droit international priv&eacute; et des trait&eacute;s internationaux.</p>
<p>En cas de litige, seuls les tribunaux ordinaires du si&egrave;ge de DoryHealth AG sont comp&eacute;tents.</p>
</div>
  );
};

export default AGBTemplateFR;